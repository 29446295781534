import {mapActions, mapGetters, mapMutations} from "vuex";
import orderAccordion from '@/modules/profile/components/order-accordion/index.vue'
//sections
export default {
  name: "profile-orders",
  components:{
    orderAccordion
  },
  data(){
    return{

    }
  },
  created() {
    this.fetchOrders()
  },
  computed:{
    ...mapGetters({
      orders: 'profile/orders'
    }),
  },

  methods:{
    ...mapActions({
      fetchOrders: 'profile/FETCH_ORDERS'
    }),
  }
}