import {VueSlideToggle} from 'vue-slide-toggle';
import {mapActions, mapGetters} from "vuex";

export default {
  name: 'accordion',
  props: {
    order: {
      type: Object,
      default: ()=> {}
    }
  },
  data() {
    return {
      expanded: false,
      popup: false,
      payload:{
        id: this.order.id,
        method: 'paypal'
      }
    }
  },
  components: {
    VueSlideToggle
  },
  computed: {
    ...mapGetters({
      invoice: 'order/invoiceResult',
      loading: 'order/isInvoiceLoading'
    })
  },
  methods: {
    ...mapActions({
      createInvoice: 'order/CREATE_INVOICE'
    }),
    toggleCardState() {
      this.expanded = !this.expanded;
    },
    pay(){
      this.createInvoice(this.payload).then(() => {
        console.log(this.invoice.url);
        this.popup = false
        window.location.href = this.invoice.url
      }).catch(error => {
        if (error.status === 422) {
          this.$toasted.error(error.data.message);
          const errors = error.data.errors;
          for(const i in errors){
            errors[i].forEach(e => {
              this.$toasted.error(e);
            })
          }
        }
      })
    }
  },
}